<template>
	<div class="content-wrapper">
		<bo-page-title />
		<template v-if="isList">
			<div class="content">
				<div class="card">
					<div class="bg-white card-header">
						<div class="row align-items-center">
							<div class="col-md-4 col-lg-5">
								<div class="form-row align-items-center">
									<div class="col-lg-4">
										<h5 class="card-title font-weight-semibold">Rujukan List</h5>
									</div>
									<div class="col-lg-8">
										<div class="form-group mb-0">
											<div class="input-group">
												<date-range-picker ref="picker" :locale-data="datePickerConfig.locale"
													:autoApply="datePickerConfig.autoApply" v-model="dateRange" :opens="'right'"
													:ranges="datePickerConfig.ranges" @update="updateValues">
													<template v-slot:input="picker">
														{{ picker.startDate | date }} - {{ picker.endDate | date }}
													</template>
												</date-range-picker>
												<div class="input-group-append calendar-group">
													<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card-header">
						<div class="row">
							<div class="col-md-8 col-lg-9">
								<div class="row g-1">
									<div class="col-md-4 col-lg-2">
										<v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.status_rujukan"
											:options="Config.mr.statusRujukan" label="text" :reduce="v=>v.value" :clearable="true"></v-select>
									</div>
									<div class="col-md-4 col-lg-2">
										<v-select placeholder="Pilih Tipe Rujukan" @input="doFill" v-model="filter.tipe_rujukan"
											:options="Config.mr.tipeRujukan" label="text" :reduce="v=>v.value" :clearable="true"></v-select>
									</div>
									<div class="col-md-6 col-lg-3">
										<v-select placeholder="Pilih Cara Bayar" @input="doFill" v-model="filter.cara_bayar"
											:options="Config.mr.statusJaminan" label="text" :reduce="v=>v.value" :clearable="true"></v-select>
									</div>

								</div>
							</div>
							<div class="col-md-4 col-lg-3">
								<div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
									<input class="form-control" v-model="filter.search" @input="doSearch"
										placeholder="Ketik Nama/No. RM Pasien" />
									<div class="form-control-feedback">
										<i class="icon-search4 text-indigo"></i>
									</div>

									<b-button class="ml-1 d-inline-flex align-items-center" variant="outline-success" id="resetBtn"
										@click="doResetData()">Reset</b-button>
								</div>
							</div>
						</div>
					</div>
					<div class="table-responsive">
						<table
							class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
							<thead>
								<tr>
									<th>No</th>
									<th>Tanggal Pembuatan Rujukan</th>
									<th>Nama / No. RM</th>
									<th>Dokter Perujuk</th>
									<th>Tipe Rujukan</th>
									<th>Cara Bayar</th>
									<th>Status</th>
									<th width="120">Aksi</th>
								</tr>
							</thead>
							<tbody v-if="(dataList||[]).length">
								<tr v-for="(v,k) in (dataList||[])" :key="k">
									<td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>
										<span v-if="v.ar_rujukan_date">
											{{v.ar_rujukan_date | moment("DD MMMM YYYY, HH:mm")}} WIB
										</span>
										<span v-else> - </span>
									</td>
									<td>
										<span>{{uppercaseWord(v.ap_fullname)||"-"}}</span>
										<p>{{v.ap_code||"-"}}</p>
									</td>
									<td>{{v.bu_full_name || "-"}}</td>
									<td>
										<span v-if="v.ar_type_rujukan == 'RK'">Rujuk Kasus</span>
										<span v-else-if="v.ar_type_rujukan == 'RI'">Rujuk Internal</span>
										<span v-else-if="v.ar_type_rujukan == 'RB'">Rujuk Balik PRB</span>
										<span v-else-if="v.ar_type_rujukan == 'RP'">Rujuk Parsial</span>
										<span v-else-if="v.ar_type_rujukan == 'RBN'">Rujuk Balik Non PRB</span>

									</td>
									<td>{{getConfigDynamic(Config.mr.statusJaminan,v.arres_jaminan)||"-"}}</td>
									<td>
										<span class="badge badge-warning mb-1" v-if="v.arr_status == 'Q'">Menunggu</span>
										<span class="badge badge-success mb-1" v-else>Selesai</span>
									</td>
									<td>
										<router-link class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info"
											:to="{name: $route.name, params: {pageSlug: v.arr_id}}" data-toggle="tooltip" data-placement="top"
											title="View"><i class="icon-eye"></i></router-link>

										<a @click="doDelete(k, v)" v-if="v.arr_status == 'D'"
											class="btn btn-icon rounded-round btn-sm mr-1 alpha-danger border-danger" data-toggle="tooltip"
											data-placement="top" title="Hapus"><i class="icon-trash"></i></a>

										<template v-if="v.arr_status == 'D'">
											<a @click="downloadReport(v)"
												class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info mt-1" data-toggle="tooltip"
												data-placement="top" title="Print"><i class="icon-printer"></i></a>
										</template>

									</td>
								</tr>
							</tbody>
							<tbody v-if="data.data.length == 0">
								<tr>
									<td colspan="99">
										<div class="text-center">
											<h4 align="center"><span
													v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian
													tidak ditemukan</span>
												<span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
										</div>
									</td>
								</tr>
							</tbody>

							<tbody v-if="!data.data">
								<tr>
									<td colspan="99">
										<div class="skeletal-comp"></div>
									</td>
								</tr>
								<tr>
									<td colspan="99">
										<div class="skeletal-comp"></div>
									</td>
								</tr>
								<tr>
									<td colspan="99">
										<div class="skeletal-comp"></div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">
						<b-pagination class="mb-0" v-model="pageNo" :per-page="data.per_page" :total-rows="data.total" />
					</b-card-footer>
				</div>
				<!-- /dashboard content -->
			</div>
		</template>
		<template v-else>
			<Form />
		</template>
	</div>
</template>

<script>
    import GlobalVue from '@/libs/Global.vue'
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    const moment = require('moment')
    import Gen from '@/libs/Gen.js'
    import Form from './Form.vue'

    const _ = global._
    import $ from 'jquery'
    export default {
        extends: GlobalVue,
        data(){
            return {
                idKey:'arr_id',
                datePickerConfig: {
                    startDate: new Date(),
                    endDate: new Date(),
                    autoApply: true,
                    ranges: {
                        'Hari Ini': [new Date(), new Date()],
                        '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                        '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                    },
                    applyClass: 'btn-sm btn-primary',
                    cancelClass: 'btn-sm btn-light',
                    locale: {
                        applyLabel: 'Terapkan',
                        cancelLabel: 'Batal',
                        direction: 'ltr',
                        format: 'mm/dd/yyyy',
                        separator: ' - ',
                    }
                },
                dateRange: {
                    startDate: new Date(),
                    endDate: new Date(),
                },
                rowEdit : {},
                rowPasien : {},
                detailData: false,
                dataRM: {}
            }
        },
  	    components:{DateRangePicker,Form},
        methods: {        
            doConvertDate(){
                this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
                this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
            },
            doPageOne(){
                this.filter.page = 1
            },
            doFill(){
                this.doPageOne()
                this.doFilter()
            },
            updateValues(e){
                this.doConvertDate()
                this.doFill()
            },
            doSearch: _.debounce(function(){
                this.doPageOne()
                this.doFilter()
            },500),
            getConfigDynamic(master,value){
                let text = ''
                if(value){
                    let index = (master||[]).findIndex(x => x.value == value)
                    if(index !== -1){
                        text = master[index]['text']
                    }
                }
                return text
            },

            downloadReport(row){
                // <span v-if="v.ar_type_rujukan == 'RK'">Rujuk Kasus</span>
                // <span v-else-if="v.ar_type_rujukan == 'RI'">Rujuk Internal</span>
                // <span v-else-if="v.ar_type_rujukan == 'RB'">Rujuk Balik</span>
                // <span v-else-if="v.ar_type_rujukan == 'RP'">Rujuk Parsial</span>
                
                if(row.ar_type_rujukan == 'RK'){
                    row.link = "suratRujukanExternal"
                    row.title = "Surat Rujukan External"
                }else if(row.ar_type_rujukan == 'RP'){
                    row.link = "suratRujukanExternal"
                    row.title = "Surat Rujukan External"
                }else if(row.ar_type_rujukan == 'RI'){
                    row.link = "suratRujukanInternal"
                    row.title = "Surat Rujukan Internal"
                }else if(row.ar_type_rujukan == 'RB'){
                    row.link = "suratRujukanBalik"
                    row.title = "Surat Rujukan Balik"
                }else if(row.ar_type_rujukan == 'RBN'){
                    row.link = "suratRujukanBalikNon"
                    row.title = "Surat Rujukan Balik Non PRB"
                }

                let link = row.link
                let name = row.title

                let data = {
                    exptype: 'pdf', type: "export", pasienId: row.arr_ap_id, regId: row.arr_reg_id
                }

                let self = this

                $.ajax({
                        type: "POST",
                        url: process.env.VUE_APP_API_URL + `/report/${link}?token=IXs1029102asoaksoas102901290`,
                        data: data,
                        cache: false,
                        xhrFields:{
                            responseType: 'blob'
                        },
                        success: data => 
                        {
                            self.loadingOverlay = false
                            var link = document.createElement('a')
                            link.href = window.URL.createObjectURL(data)
                            link.download = `${name}-${moment().format("YYYY-MM-DD")}.pdf`
                            link.click()
                        },
                        fail: data => {
                            self.loadingOverlay = false
                            alert('Not downloaded')
                        }
                })

            }
        },
        mounted(){
            this.doConvertDate()      
            this.apiGet()
        },
        filters: {
            date(val) {
                return val ? moment(val).format("D MMM YYYY") : ""
            }
        },
        watch:{
            $route(){
                this.apiGet()
            },
            '$route.params.pageSlug':function(){
                this.$set(this,'filter',{})
            }
        }

    }
</script>

